.Loading {
  z-index: 150;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(15, 15, 15, 0.74);
}

.circle {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 12px 64px 0 rgba(0, 0, 0, 0.16);
}

.loader {
  margin-top: 17px;
  display: flex;

  span + span {
    margin-left: 15px;
  }

  span {
    border-radius: 50%;
    display: block;
    width: 12px;
    height: 12px;
    background-color: #7c00ff;

    &.first {
      animation: load 1s infinite 1s;
      animation-delay: 0s;
    }

    &.second {
      animation: load 1s infinite 1s;
      animation-delay: .2s;
    }

    &.third {
      animation: load 1s infinite 1s;
      animation-delay: .4s;
    }
  }
}

.title {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -.43px;
  color: #333236;
  margin-top: 20px;
}

.message {
  margin-top: 15px;
  text-align: center;
  color: #ffffff;
}

.Loading.dark {
  .circle {
    background-color: #2b2e3c;
  }

  .loader {
    span {
      background-color: #33cccc;
    }
  }

  .title {
    color: #ffffff;
  }
}

@keyframes load {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(7px);
  }

  100% {
    transform: translateY(0px);
  }
}
